/* Dashboard.css */

.dashboard-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .dashboard-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centers content vertically */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #333;
    text-align: center;
    transition: transform 0.2s ease;
    cursor: pointer;
    height: 220px;
    width: 100%;
  }
  
  .dashboard-card:hover {
    transform: scale(1.05);
  }
  
  .dashboard-icon {
    font-size: 3rem; /* Increased icon size */
    margin-bottom: 10px;
    font-weight: bold; /* Makes icon bold if applicable */
  }
  
  .dashboard-title {
    font-size: 1.5rem; /* Increased title size */
    font-weight: bold; /* Makes title bold */
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .dashboard-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .dashboard-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .dashboard-container {
      grid-template-columns: 1fr;
    }
  }
  